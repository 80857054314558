<template>
	<v-sheet class="group" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="2" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.group_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="10" class="py-1 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-layout class="justify-end">
						<div class="mx-2" style="max-width: 300px">
							<date-range-picker
								v-on:click:clear="
									s_search['date-range'] = [];
									searchOrders();
								"
								hide-details
								:disabled="pageLoading"
								hide-top-margin
								clearable
								v-model="s_search['date-range']"
							></date-range-picker>
						</div>
						<div class="mx-2" style="max-width: 300px">
							<select-input
								hide-top-margin
								v-on:click:clear="
									s_search['service-by'] = [];
									searchOrders();
								"
								clearable
								:items="supplier_list"
								hide-details
								:disabled="pageLoading"
								custom-class="pt-0"
								placeholder="Service By"
								multiple
								v-model="s_search['service-by']"
							></select-input>
						</div>
						<div class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['type'] = [];
									searchOrders();
								"
								clearable
								hide-top-margin
								:items="item_type_items"
								hide-details
								:disabled="pageLoading"
								custom-class="pt-0"
								placeholder="Type"
								multiple
								v-model="s_search['type']"
							></select-input>
						</div>
						<div class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['acknowledge'] = [];
									searchOrders();
								"
								clearable
								:items="acknowledge_items"
								hide-details
								hide-top-margin
								:disabled="pageLoading"
								custom-class="pt-0"
								placeholder="Acknowledge Status"
								multiple
								v-model="s_search['acknowledge']"
							></select-input>
						</div>
						<div class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['status-type'] = [];
									searchOrders();
								"
								clearable
								hide-top-margin
								:items="status_items"
								hide-details
								:disabled="pageLoading"
								custom-class="pt-0"
								placeholder="Status"
								multiple
								v-model="s_search['status-type']"
							></select-input>
						</div>
						<div class="mx-2" style="max-width: 300px">
							<select-input
								v-on:click:clear="
									s_search['cost-center'] = [];
									searchOrders();
								"
								clearable
								hide-top-margin
								:items="cost_center_items"
								hide-details
								:disabled="pageLoading"
								custom-class="pt-0"
								placeholder="Cost Center"
								multiple
								v-model="s_search['cost-center']"
							></select-input>
						</div>
						<div class="d-flex">
							<v-btn
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								:disabled="pageLoading"
								v-on:click="searchOrders()"
								><v-icon center> mdi-magnify </v-icon></v-btn
							>
							<v-btn
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								:disabled="pageLoading"
								v-on:click="create_service()"
								v-if="getPermission('service::create')"
								><v-icon left> mdi-plus </v-icon> Create</v-btn
							>
							<v-menu
								bottom
								left
								origin="center center"
								transition="slide-y-transition"
								rounded="0"
								offset-y
								max-height="400px"
								:close-on-content-click="false"
								content-class="white-background"
								v-if="getPermission('setting::edit')"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										color="blue darken-4"
										class="text-white"
										tile
										:disabled="pageLoading"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon>mdi-table-edit</v-icon>
									</v-btn>
								</template>
								<Draggable
									tag="ul"
									v-model="draggableThead"
									class="draggable-group"
									handle=".draggable-drag-icon"
									v-on:change="updateTable('group')"
								>
									<template v-for="cols in draggableThead">
										<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
											<v-checkbox
												dense
												v-model="draggableTheadShow"
												v-bind:value="cols.key"
												:label="cols.name"
												:disabled="cols.fixed || pageLoading"
												color="blue"
												hide-details
												class="mt-0 mb-0"
												v-on:change="updateTableVisiblity('group')"
											></v-checkbox>
											<v-icon
												v-if="!pageLoading && !cols.fixed"
												class="draggable-action draggable-drag-icon"
												right
												color="blue"
												>mdi-drag</v-icon
											>
											<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
										</li>
									</template>
								</Draggable>
							</v-menu>
							<v-btn
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								:disabled="pageLoading"
								v-on:click="export_services()"
								><v-icon> mdi-file-excel-outline </v-icon></v-btn
							>
							<v-btn
								:disabled="pageLoading"
								color="blue darken-4"
								class="text-white"
								v-on:click="exportDialogNew = true"
								tile
								depressed
							>
								<v-icon dark left>mdi-database-export</v-icon> Export
							</v-btn>
						</div>
					</v-layout>
				</template>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Service #, Service Remark, Service Comment, Asset #, Asset Name"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="service"
			delete-endpoint="asset/service/"
			:dialog-update="true"
			:dialog-view="true"
			v-on:reload:content="filterRows"
			v-on:update:dialog="updateDialog($event)"
			v-on:view:dialog="viewDialog($event, 'detail')"
			delete-note="All transactions of this service will also be deleted."
		></Table>
		<ExportDialogNew
			endpoint="service/export?type=service"
			title="Service"
			:cols="draggableThead"
			:export-dialog.sync="exportDialogNew"
			v-on:close="exportDialogNew = false"
		></ExportDialogNew>
		<SelectRequested
			v-on:selectRelatedTo="selectRelatedTo"
			v-on:close="selectRelateddialog = false"
			:checkout-dialog="selectRelateddialog"
			:asset-only="true"
		></SelectRequested>
		<ServiceStartTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="relatedUuid"
			:service-array="serviceArr"
			v-on:success="
				serviceDialog = false;
				getListing();
			"
			v-on:close="serviceDialog = false"
			:service-dialog="serviceDialog"
		></ServiceStartTemplate>
		<template v-if="serviceScheduleDialog">
			<ServiceScheduleTemplate
				type="Asset"
				endpoint="asset"
				:type-uuid="relatedUuid"
				:service-array="serviceArr"
				v-on:success="
					serviceScheduleDialog = false;
					getListing();
				"
				v-on:close="serviceScheduleDialog = false"
				:service-dialog="serviceScheduleDialog"
			></ServiceScheduleTemplate>
		</template>
		<Dialog :dialog="detailDialog" :dialog-width="dialogWidth" dense>
			<template v-slot:title>
				<v-layout>
					<v-flex md8 class="my-auto d-flex">
						<div class="d-flex align-items-center">
							<span class="mr-4">{{ service.service_type }} Service</span>
							<Chip
								tooltip
								tooltip-text="Service #"
								class="mr-4"
								:text="service.barcode"
								color="blue darken-4 white--text"
							></Chip>
							<Chip
								tooltip
								tooltip-text="Service By"
								class="mr-4"
								:text="service.service_by_formatted"
								color="blue darken-4 white--text"
							></Chip>
							<Chip
								tooltip
								tooltip-text="Service Type"
								class="mr-4"
								:text="service.service_type_formatted"
								:color="service.service_type == 'on-site' ? 'brown darken-4' : 'blue-grey darken-4'"
							></Chip>
							<Chip
								tooltip
								tooltip-text="Invoiced"
								v-if="service.is_invoiced == 1"
								class="mr-4"
								text="Invoiced"
								color="orange darken-2"
							></Chip>
							<Status tooltip tooltip-text="Status" :status="service.status_value"></Status>
						</div>
					</v-flex>
					<v-flex md4 class="text-right">
						<template v-if="service.can_start">
							<v-btn
								color="blue darken-4 text-white"
								class="mr-2"
								depressed
								tile
								:disabled="pageLoading"
								:loading="pageLoading"
								v-on:click="startScheduledService()"
								><v-icon small left>mdi-hammer-wrench</v-icon>Start</v-btn
							>
						</template>
						<template v-if="service.can_stop">
							<v-btn
								v-if="serviceTab != 'detail' && service.status == 1"
								v-on:click="statusDialog = true"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								><v-icon small left>mdi-format-list-checks</v-icon>Complete</v-btn
							>
						</template>
						<v-btn
							v-if="service.can_acknowledge"
							v-on:click="
								acknowledgeDialog = true;
								initialize_signature();
							"
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							><v-icon small left>mdi-format-list-checks</v-icon>Acknowledge</v-btn
						>
						<template v-if="getPermission('invoice::create')">
							<v-btn
								v-if="
									service.is_acknowledged == 1 && service.acknowledge_status == 1 && service.is_invoiced == 0
								"
								v-on:click="convert_to_invoice()"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								><v-icon small left>mdi-file-refresh-outline</v-icon>Invoice</v-btn
							>
						</template>
						<template v-if="service.pdf_url">
							<v-btn
								v-on:click="download_pdf()"
								color="blue darken-4 text-white"
								class="mx-2"
								depressed
								tile
								><v-icon small left>mdi-file-download-outline</v-icon>Download</v-btn
							>
						</template>
						<v-btn
							v-on:click="
								detailDialog = false;
								serviceTab = 'detail';
							"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-close</v-icon>Close</v-btn
						>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<div>
					<v-tabs
						v-model="serviceTab"
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent"
						active-class="blue darken-4 text-white"
						hide-slider
						style="position: sticky; top: 0; z-index: 99"
					>
						<v-tab href="#detail">
							<span class="tab-svg-icon mr-2">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/services.svg')" />
								<!--end::Svg Icon-->
							</span>
							Details
						</v-tab>
						<v-tab v-if="service.can_fill" href="#service-form">
							<v-icon small left>mdi-file-chart-outline</v-icon> Service Form
						</v-tab>
						<v-tab v-if="service.can_view" href="#service-form-detail">
							<v-icon small left>mdi-file-chart-outline</v-icon> Service Form
						</v-tab>
						<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
						<v-tab href="#comment"> <v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab>
					</v-tabs>
					<v-tabs-items v-model="serviceTab" style="min-height: calc(100vh - 300px)">
						<v-tab-item value="detail">
							<div class="mx-4 mb-4">
								<div class="overflow-y">
									<v-expansion-panels v-model="expansion_panel" mandatory>
										<v-expansion-panel>
											<v-expansion-panel-header>
												<div class="d-flex align-items-center">
													<span class="font-level-3-bold mr-4">Service</span>
													<Chip
														tooltip
														tooltip-text="Service #"
														class="mr-4"
														:text="ObjectKey(service, 'barcode', 'N/A')"
														color="blue darken-4 white--text"
													></Chip>
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table
													width="100%"
													class="detail-table scattered-table mt-3"
													style="table-layout: fixed"
												>
													<tr>
														<th class="p-2" width="250px">Service Type</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="service_type_formatted"
																label="service type"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Service Start Date (expected)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="expected_start_date_formatted"
																label="service start date (expected)"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Service Performed by</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="service_by_formatted"
																label="Service Performed by"
															></ShowValue>
															(<ShowValue
																:object="service"
																v-if="service.service_by == 1"
																object-key="engineer.display_name"
																label="engineer"
															></ShowValue>
															<ShowValue
																:object="service"
																v-else
																object-key="subcon.display_name"
																label="subcon"
															></ShowValue
															>)
														</td>
														<th class="p-2" width="250px">Service End Date (expected)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="expected_end_date_formatted"
																label="Service End Date (expected)"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Created By</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="created_by.display_name"
																label="Created By"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Service Start Date (actual)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="actual_start_date_formatted"
																label="service start date (actual)"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Created At</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="added_at_formatted"
																label="Created At"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Service End Date (actual)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="actual_end_date_formatted"
																label="Service End Date (actual)"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Remark</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue :object="service" object-key="description" label="Remark"></ShowValue>
														</td>
													</tr>
													<tr v-if="service.service_by == 2">
														<th class="p-2" width="250px">Subcon Remark</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="service"
																object-key="subcon_remark"
																label="Subcon Remark"
															></ShowValue>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
										<v-expansion-panel v-if="service.is_acknowledged == 1">
											<v-expansion-panel-header>
												<div class="d-flex align-items-center">
													<span class="font-level-3-bold">Acknowledgement</span>
													<Chip
														tooltip
														tooltip-text="Acknowledge Status"
														class="ml-4"
														:text="service.acknowledge_status_formatted"
														:color="
															service.acknowledge_status == 1
																? 'green lighten-1 white--text'
																: 'red lighten-1 white--text'
														"
													></Chip>
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table
													width="100%"
													class="detail-table scattered-table mt-3"
													style="table-layout: fixed"
												>
													<tr>
														<th class="p-2" width="250px">Acknowledge By</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="acknowledge_by_relation.display_name"
																label="acknowledge by"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Acknowledge At</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="acknowledge_at_formatted"
																label="acknowledge at"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Acknowledge Status</th>
														<td class="font-level-1 p-2" colspan="3">
															<Chip
																:text="service.acknowledge_status_formatted"
																:color="
																	service.acknowledge_status == 1
																		? 'green lighten-1 white--text'
																		: 'red lighten-1 white--text'
																"
															></Chip>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
										<v-expansion-panel v-if="service.is_invoiced == 1">
											<v-expansion-panel-header>
												<div class="d-flex align-items-center">
													<span class="font-level-3-bold mr-4">Invoice</span>
													<template v-if="ObjectHasKey(service, 'invoice_detail.id')">
														<Chip
															tooltip
															tooltip-text="Invoice #"
															class="mr-4"
															:text="ObjectKey(service, 'invoice_detail.barcode', 'N/A')"
															color="blue darken-4 white--text"
														></Chip>
														<Chip
															tooltip
															tooltip-text="Invoice Status"
															class="mr-4"
															:text="ObjectKey(service, 'invoice_detail.status_relation.text', 'N/A')"
															:color="ObjectKey(service, 'invoice_detail.status_relation.color', 'N/A')"
															:text-color="ObjectKey(service, 'invoice_detail.status_relation.textcolor', 'N/A')"
														></Chip>
														<Chip
															tooltip
															tooltip-text="Invoice Approval"
															v-if="ObjectKey(service, 'invoice_detail.is_approved', 0) == 1"
															:text="ObjectKey(service, 'invoice_detail.approve_status_formatted', 'N/A')"
															:color="
																ObjectKey(service, 'invoice_detail.approve_status', 0) == 1
																	? 'green lighten-1 white--text'
																	: 'red lighten-1 white--text'
															"
														></Chip>
													</template>
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table
													width="100%"
													class="detail-table scattered-table mt-3"
													style="table-layout: fixed"
												>
													<tr>
														<th class="p-2" width="250px">Invoice Number</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="invoice_detail.invoice_id"
																label="invoice number"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Invoice Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="invoice_detail.date_formatted"
																label="Invoice Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Total Amount</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="invoice_detail.total_amount_formatted"
																label="total amount"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Paid Amount</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="invoice_detail.paid_amount_formatted"
																label="paid amount"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Created At</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="invoice_detail.added_at_formatted"
																label="created at"
															></ShowValue>
														</td>
														<th class="p-2" width="250px">Created By</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="invoice_detail.created_by.display_name"
																label="created by"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="250px">Remark</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="service"
																object-key="invoice_detail.remark"
																label="remark"
															></ShowValue>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
										<v-expansion-panel>
											<v-expansion-panel-header>
												<div class="d-flex align-items-center">
													<span class="font-level-3-bold mr-4">Asset</span>
													<Chip
														v-if="false"
														tooltip
														tooltip-text="Asset #"
														class="mr-4"
														:text="ObjectKey(service, 'asset_relation.barcode', 'N/A')"
														color="blue darken-4 white--text"
													></Chip>
												</div>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<table
													width="100%"
													class="detail-table scattered-table mt-3"
													style="table-layout: fixed"
												>
													<tr>
														<th class="p-2" width="300px">Asset ID</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.id_number"
																label="Asset ID"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Asset Name</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.name"
																label="Asset Name"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">System Owner</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.system_owner"
																label="System Owner"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Department</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.department"
																label="Department"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Manufacturer</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.manufacturer"
																label="Manufacturer"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Location</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.location"
																label="Location"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Model No.</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.model_no"
																label="Model No."
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Serial No.</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.serial_no"
																label="Serial No."
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Calibration Frequency (in months)</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.calibration_frequency"
																label="Calibration Frequency"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Last Calibration Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.last_calibration_date_formatted"
																label="Last Calibration Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Reminder Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.reminder_formatted"
																label="Reminder Date"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Next Calibration Date</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.next_calibration_date_formatted"
																label="Next Calibration Date"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Calibration Point And Tolerance</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.calibration_point_and_tolerance"
																label="Calibration Point And Tolerance"
															></ShowValue>
														</td>
														<template v-if="!isSubcon">
															<th class="p-2" width="300px">Cost Centre (SGD)</th>
															<td class="font-level-1 p-2">
																<ShowValue
																	:object="service"
																	object-key="asset_relation.cost_centre_formatted"
																	label="Cost Centre"
																></ShowValue>
															</td>
														</template>
													</tr>
													<tr>
														<th class="p-2" width="300px">Calibration Location</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.calibration_location"
																label="Calibration Location"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Previous Vendor</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.previous_vendor"
																label="Previous Vendor"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">AML Proposal</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.aml_proposal"
																label="AML Proposal"
															></ShowValue>
														</td>
														<template v-if="!isSubcon">
															<th class="p-2" width="300px">Contract Price/Quote Price (SGD)</th>
															<td class="font-level-1 p-2">
																<ShowValue
																	:object="service"
																	object-key="asset_relation.price_formatted"
																	label="Contract Price/Quote Price"
																></ShowValue>
															</td>
														</template>
													</tr>
													<tr>
														<th class="p-2" width="300px">BPO/PO Number</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.bpo_po_number"
																label="BPO/PO Number"
															></ShowValue>
														</td>
														<th class="p-2" width="300px">Frequency Per Year</th>
														<td class="font-level-1 p-2">
															<ShowValue
																:object="service"
																object-key="asset_relation.frequency_per_year"
																label="Frequency Per Year"
															></ShowValue>
														</td>
													</tr>
													<template v-if="!isSubcon">
														<tr>
															<th class="p-2" width="300px">Price Per Annum (SGD)</th>
															<td class="font-level-1 p-2" colspan="3">
																<ShowValue
																	:object="service"
																	object-key="asset_relation.price_per_annum_sgd_formatted"
																	label="Price Per Annum"
																></ShowValue>
															</td>
														</tr>
													</template>
													<tr>
														<th class="p-2" width="300px">Process Range</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="service"
																object-key="asset_relation.process_range"
																label="Process Range"
															></ShowValue>
														</td>
													</tr>
													<tr>
														<th class="p-2" width="300px">Comment</th>
														<td class="font-level-1 p-2" colspan="3">
															<ShowValue
																:object="service"
																object-key="asset_relation.comment"
																label="Comment"
															></ShowValue>
														</td>
													</tr>
												</table>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item v-if="service.can_fill" value="service-form">
							<ServiceFormTemplate v-model="service_form"></ServiceFormTemplate>
						</v-tab-item>
						<v-tab-item v-if="service.can_view" value="service-form-detail">
							<ServiceFormTemplate readonly v-model="service_form"></ServiceFormTemplate>
						</v-tab-item>
						<v-tab-item value="file">
							<Files
								:reload="reload_now"
								v-on:reload="reload_now = false"
								class="mx-4"
								type-text="Service Files"
								type="service"
								:type-uuid="service.uuid"
								c-type="5"
							></Files>
						</v-tab-item>
						<v-tab-item value="comment">
							<Comments
								class="mx-4"
								type-text="Service"
								type="service"
								:type-uuid="service.uuid"
							></Comments>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</template>
		</Dialog>
		<ServiceExtendTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="assetUuid"
			:service-uuid="service.uuid"
			v-on:success="
				serviceExtendDialog = false;
				detailDialog = false;
				serviceTab = 'detail';
				reload_now = true;
				getListing();
			"
			v-on:close="serviceExtendDialog = false"
			:service-dialog="serviceExtendDialog"
		></ServiceExtendTemplate>
		<ServiceCompleteTemplate
			type="Asset"
			endpoint="asset"
			:type-uuid="assetUuid"
			:service-uuid="service.uuid"
			v-on:success="
				serviceCompleteDialog = false;
				detailDialog = false;
				serviceTab = 'detail';
				reload_now = true;
				getListing();
			"
			v-on:close="serviceCompleteDialog = false"
			:service-dialog="serviceCompleteDialog"
		></ServiceCompleteTemplate>
		<Dialog :dialog="inventoryDialog" :dialog-width="dialogWidth - 200" dense>
			<template v-slot:title> Link Perishables Items </template>
			<template v-slot:body>
				<LinkAssetInventoryItem v-model="inventories" class="mx-4 mb-4"></LinkAssetInventoryItem>
			</template>
			<template v-slot:action>
				<v-btn v-on:click="closeLinkInventory()" color="blue darken-4 text-white" depressed tile
					>Close</v-btn
				>
				<v-btn v-on:click="linkInventory()" color="blue darken-4 text-white" depressed tile
					>Link Perishables</v-btn
				>
			</template>
		</Dialog>
		<Dialog :dialog="statusDialog">
			<template v-slot:title>Complete Asset Service</template>
			<template v-slot:body>
				<div class="text-center d-flex flex-column align-center">
					<p class="font-level-3-bold my-5">Are you sure, you want to complete the service?</p>
					<div v-if="service.service_by == 2" class="px-10 w-100 text-left custom-border-top">
						<label for="subcon-remark" class="font-level-3-bold mt-4"> Remark </label>
						<TextAreaInput
							id="subcon-remark"
							hide-details
							:disabled="completeLoading"
							:loading="completeLoading"
							placeholder="Enter remarks..."
							v-model="subconRemark"
						></TextAreaInput>
					</div>
				</div>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="completeLoading"
					:disabled="completeLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="completeService()"
				>
					Yes! Complete
				</v-btn>
				<v-btn depressed tile :disabled="completeLoading" v-on:click="statusDialog = false">
					No! Close
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="acknowledgeDialog">
			<template v-slot:title>Acknowledge Asset Service</template>
			<template v-slot:body>
				<div class="text-center">
					<p class="font-level-3-bold my-5">Are you sure, you want to acknowledge the service?</p>
					<v-layout>
						<v-flex md2></v-flex>
						<v-flex md8>
							<div class="px-4 signature-loaded" id="engineer-signature" style="position: relative">
								<div class="engineer-signature dashed-border-light-grey" style="background-color: #ddd">
									<canvas ref="engineer-signature"></canvas>
								</div>
								<v-btn
									style="bottom: 2px; position: absolute; right: 18px"
									icon
									small
									v-on:click="clear_signature()"
								>
									<v-icon>mdi-close-circle-outline</v-icon>
								</v-btn>
							</div>
						</v-flex>
						<v-flex md2></v-flex>
					</v-layout>
					<v-layout class="mt-4">
						<v-flex md2></v-flex>
						<v-flex md8 class="my-1 mx-8">
							<table width="100%">
								<tr>
									<td class="font-level-3-bold" align="left">Name</td>
									<td>
										<TextInput
											hide-details
											hide-top-margin
											:disabled="acknowledgeLoading"
											:loading="acknowledgeLoading"
											id="alcon-name"
											:rules="[vrules.required(alcon_name, 'Name')]"
											:class="{ required: !alcon_name }"
											placeholder="Name"
											v-model="alcon_name"
										></TextInput>
									</td>
								</tr>
								<tr>
									<td colspan="2" height="10px"></td>
								</tr>
								<tr>
									<td class="font-level-3-bold" align="left">Date</td>
									<td>
										<DatePicker
											hide-details
											hide-top-margin
											:disabled="acknowledgeLoading"
											:loading="acknowledgeLoading"
											id="alcon-date"
											placeholder="Date"
											v-model="alcon_date"
											:rules="[vrules.required(alcon_date, 'Date')]"
											:class="{ required: !alcon_date }"
										></DatePicker>
									</td>
								</tr>
							</table>
						</v-flex>
						<v-flex md2></v-flex>
					</v-layout>
					<p class="font-level-2-bold my-5 text-left red--text text--lighten-1">
						Note: Please do signature first, then you can proceed.
					</p>
				</div>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="acknowledgeLoading"
					:disabled="acknowledgeLoading || !isSigned"
					depressed
					color="blue darken-4"
					tile
					v-on:click="acknowledgeService('approve')"
				>
					Yes! Approve Service
				</v-btn>
				<v-btn
					class="white--text"
					:loading="acknowledgeLoading"
					:disabled="acknowledgeLoading || !isSigned"
					depressed
					color="red lighten-1"
					tile
					v-on:click="acknowledgeService('reject')"
				>
					Yes! Reject Service
				</v-btn>
				<v-btn depressed tile :disabled="acknowledgeLoading" v-on:click="acknowledgeDialog = false">
					No! Close
				</v-btn>
			</template>
		</Dialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import ShowValue from "@/view/components/ShowValue";
import SelectRequested from "@/view/components/Select-Checkout-Requested";
import ServiceStartTemplate from "@/view/components/ServiceStartTemplate";
import ServiceScheduleTemplate from "@/view/components/ServiceScheduleTemplate";
import TextAreaInput from "@/view/components/TextAreaInput";
import { mapGetters } from "vuex";
import { /*filter,*/ toSafeInteger, isString } from "lodash";
import ServiceExtendTemplate from "@/view/components/ServiceExtendTemplate";
import ServiceCompleteTemplate from "@/view/components/ServiceCompleteTemplate";
import Dialog from "@/view/components/Dialog";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import ApiService from "@/core/services/api.service";
import { AssetServiceLinkInventory, GetAssetServiceLinkInventory } from "@/core/lib/asset.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import LinkAssetInventoryItem from "@/view/components/LinkAssetInventoryItem";
import SelectInput from "@/view/components/SelectInput";
import DateRangePicker from "@/view/components/DateRangePicker";
import ServiceFormTemplate from "@/view/components/ServiceFormTemplate";
import Status from "@/view/components/Status";
import Chip from "@/view/components/Chip";
import ObjectPath from "object-path";
import JwtService from "@/core/services/jwt.service";
import SignaturePad from "signature_pad";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import MomentJS from "moment-timezone";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "service-listing",
	title: "Listing Service",
	mixins: [ListingMixin],
	components: {
		SelectRequested,
		Dialog,
		Files,
		Chip,
		Status,
		Comments,
		ShowValue,
		TextInput,
		DatePicker,
		SelectInput,
		DateRangePicker,
		ServiceFormTemplate,
		ServiceCompleteTemplate,
		ServiceStartTemplate,
		ServiceExtendTemplate,
		ServiceScheduleTemplate,
		TextAreaInput,
		LinkAssetInventoryItem,
	},
	data() {
		return {
			uuid: null,
			expansion_panel: 0,
			service_form: {},
			reload_now: false,
			supplier_list: [
				{ text: "Engineer", value: "engineer" },
				{ text: "Subcon", value: "subcon" },
			],
			item_type_items: [
				{ text: "On Site", value: "on-site" },
				{ text: "In Lab", value: "in-lab" },
			],
			acknowledge_items: [
				{ text: "Approved", value: "approved" },
				{ text: "Rejected", value: "rejected" },
			],
			status_items: [
				{ text: "Scheduled", value: "scheduled" },
				{ text: "Started", value: "started" },
				{ text: "Completed", value: "completed" },
				{ text: "Invoiced", value: "invoiced" },
				{ text: "Acknowledged", value: "acknowledged" },
			],
			cost_center_items: [],
			requestType: null,
			assetUuid: null,
			linkedInventories: [],
			s_search: {
				"date-range": [],
				"service-by": [],
				"status-type": [],
				acknowledge: [],
				type: [],
			},
			relatedUuid: null,
			serviceArr: {},
			isSubcon: false,
			acknowledgeLoading: false,
			acknowledgeDialog: false,
			statusDialog: false,
			completeLoading: false,
			serviceDialog: false,
			serviceCompleteDialog: false,
			serviceExtendDialog: false,
			inventoryDialog: false,
			serviceTab: "detail",
			detailDialog: false,
			service: {
				id: null,
				uuid: null,
				asset: null,
				expected_start_date: null,
				expected_end_date: null,
				actual_start_date: null,
				actual_end_date: null,
				service_by: 0,
				supplier: null,
				member: null,
				service_type: null,
				subcon_remark: null,
				description: null,
				comment: null,
				status: null,
				cost: null,
				added_by: null,
				added_at: null,
				updated_by: null,
				updated_at: null,
				service_type_formatted: null,
				service_by_formatted: null,
				expected_start_date_formatted: null,
				expected_end_date_formatted: null,
				actual_start_date_formatted: null,
				actual_end_date_formatted: null,
				added_at_formatted: null,
				updated_at_formatted: null,
				acknowledge_at_formatted: null,
				created_at: null,
				modified_at: null,
				asset_relation: {},
				engineer: {},
				subcon: {},
				created_by: {},
			},
			serviceScheduleDialog: false,
			selectRelateddialog: false,
			dialog: false,
			pageTitle: "Services",
			pageBreadcrumbs: [{ text: "Services", disabled: true }],
			endpoint: "services/asset",
			defaultFilter: {},
			inventoryList: [],
			inventories: [],
			searchLoading: false,
			formLoading: false,
			menuSearch: false,
			search: null,
			subconRemark: null,
			alcon_name: null,
			alcon_date: MomentJS().format("YYYY-MM-DD"),
			alcon_signature_el: null,
			alcon_signature: null,
			init_signature: false,
			isSigned: false,
		};
	},
	methods: {
		initialize_signature() {
			const _this = this;

			_this.alcon_name = null;
			_this.alcon_date = MomentJS().format("YYYY-MM-DD");
			_this.alcon_signature_el = null;
			_this.alcon_signature = null;
			_this.init_signature = false;
			_this.isSigned = false;

			setTimeout(function () {
				let ecanvas = _this.$refs["engineer-signature"];
				let eparentDiv = document.getElementById("engineer-signature");
				let eparentWidth = eparentDiv.offsetWidth - 32;
				let eparentHeight = eparentDiv.offsetHeight;
				ecanvas.setAttribute("width", eparentWidth);
				ecanvas.setAttribute("height", eparentHeight);
				_this.alcon_signature_el = new SignaturePad(ecanvas);
				_this.alcon_signature_el.addEventListener("endStroke", () => {
					_this.validate_signature(_this);
				});
				_this.init_signature = true;
			}, 500);
		},
		validate_signature(param) {
			if (param.alcon_signature_el) {
				if (param.alcon_signature_el.isEmpty()) {
					param.isSigned = false;
					return true;
				} else {
					param.isSigned = true;
					return true;
				}
			}
			param.isSigned = false;
		},
		clear_signature() {
			if (this.alcon_signature_el) {
				this.alcon_signature_el.clear();
			}
			this.alcon_signature = null;
			this.validate_signature(this);
		},
		export_services() {
			let token = JwtService.getToken();
			const url = `${this.$apiURL()}export/services/asset?export=1&status=all&t=${new Date().getTime()}&tz=${new Date().getTime()}&token=${token}`;
			window.open(url, "_blank");
		},
		create_service() {
			this.uuid = null;
			this.serviceArr = {};
			this.selectRelateddialog = true;
			this.requestType = "scheduled";
		},
		download_pdf() {
			window.open(this.service.pdf_url, "_blank");
		},
		convert_to_invoice() {
			const uuid = ObjectPath.get(this.service, "uuid");
			const asset_uuid = ObjectPath.get(this.service, "asset_relation.uuid");

			if (!uuid || !asset_uuid) {
				return false;
			}

			this.$router.push({
				name: "invoice-create",
				query: { service: uuid, asset: asset_uuid },
			});
		},
		acknowledgeService(status) {
			let signature = null;
			if (this.alcon_signature_el && !this.alcon_signature_el.isEmpty()) {
				signature = this.alcon_signature_el.toDataURL();
			}

			if (!this.alcon_name) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "Error ! Please enter name." }]);
				return false;
			}

			if (!this.alcon_date) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "Error ! Please select date." }]);
				return false;
			}

			this.acknowledgeLoading = true;

			ApiService.patch(`asset/${this.assetUuid}/service/${this.service.uuid}/acknowledge`, {
				status,
				signature,
				alcon_name: this.alcon_name,
				alcon_date: this.alcon_date,
			})
				.then(() => {
					this.alcon_name = null;
					this.alcon_date = MomentJS().format("YYYY-MM-DD");
					if (this.alcon_signature_el) {
						this.alcon_signature_el.clear();
					}
					this.alcon_signature_el = null;
					this.alcon_signature = null;
					this.init_signature = false;
					this.isSigned = false;

					this.service = {};
					this.assetUuid = null;
					this.serviceTab = "detail";
					this.service_form = {};
					this.detailDialog = false;
					this.serviceArr = {};
					this.serviceDialog = false;
					this.statusDialog = false;
					this.serviceScheduleDialog = false;
					this.acknowledgeDialog = false;
					this.getListing();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.acknowledgeLoading = false;
				});
		},
		completeService() {
			this.completeLoading = true;
			if (this.service.service_by == 2) {
				this.service_form = new Object();
				this.service_form["subcon-remark"] = this.subconRemark;
			}
			ApiService.patch(
				`asset/${this.assetUuid}/service/${this.service.uuid}/complete`,
				this.service_form
			)
				.then(() => {
					this.service = {};
					this.assetUuid = null;
					this.serviceTab = "detail";
					this.service_form = {};
					this.detailDialog = false;
					this.serviceArr = {};
					this.serviceDialog = false;
					this.statusDialog = false;
					this.serviceScheduleDialog = false;
					this.getListing();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.completeLoading = false;
				});
		},
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.s_search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
		selectRelatedTo(data) {
			this.relatedUuid = data.id;
			this.selectRelateddialog = false;
			if (this.requestType == "start") {
				this.serviceDialog = true;
			} else {
				this.serviceScheduleDialog = true;
			}
		},
		updateDialog(uuid) {
			this.uuid = uuid;
			this.getServiceDetail();
			//this.dialog = true;
		},
		viewDialog(uuid, tab) {
			ApiService.get(`asset/service/${uuid}`)
				.then(({ data }) => {
					this.service = data;
					this.isSubcon = data.is_subcon;
					this.assetUuid = ObjectPath.get(data, "asset_relation.uuid");
					this.serviceTab = tab;
					this.service_form = data.service_form;
					this.detailDialog = true;
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				});
		},
		getLinkedInventories() {
			if (!this.service.uuid) {
				return false;
			}

			GetAssetServiceLinkInventory(this.service.asset_uuid, this.service.uuid)
				.then((data) => {
					this.linkedInventories = data;
				})
				.catch((error) => {
					this.logError(error);
				});
		},
		startScheduledService() {
			this.pageLoading = true;
			ApiService.patch(`asset/${this.assetUuid}/service/${this.service.uuid}/start`)
				.then(() => {
					const service_by = ObjectPath.get(this.service, "service_by", 0);

					if (service_by == 2) {
						this.viewDialog(this.service.uuid, "file");
					}

					if (service_by == 1) {
						this.viewDialog(this.service.uuid, "service-form");
					}

					this.getListing();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getServiceDetail() {
			this.pageLoading = true;
			ApiService.get(`asset/service/${this.uuid}`)
				.then(({ data }) => {
					this.serviceArr = data;
					if (data.status == 1) {
						this.serviceDialog = true;
					} else if (data.status == 3) {
						this.serviceScheduleDialog = true;
					}
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeLinkInventory() {
			this.inventoryList = [];
			this.inventories = [];
			this.inventoryDialog = false;
			this.searchLoading = false;
			this.formLoading = false;
			this.menuSearch = false;
			this.search = null;
		},
		linkInventory() {
			if (!this.service.uuid) {
				return false;
			}

			if (!this.inventories.length) {
				this.$store.commit(
					SET_ERROR,
					this.errors.concat([{ model: true, message: "Error ! Add atleast one inventory." }])
				);
				return false;
			}

			this.formLoading = true;
			AssetServiceLinkInventory(this.service.asset_uuid, this.service.uuid, {
				inventory: this.inventories,
			})
				.then((data) => {
					this.linkedInventories = data;
					this.closeLinkInventory();
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Perishables has been successfully linked to service." },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.formLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["tbody", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 80);
		},
	},
	watch: {
		"$route.query"() {
			const open_dialog = ObjectPath.get(this.$route, "query.opendialog", false);
			const open_uuid = ObjectPath.get(this.$route, "query.openuuid", null);

			if (open_dialog && open_uuid) {
				this.viewDialog(open_uuid, "detail");
			}
		},
	},
	mounted() {
		const open_dialog = ObjectPath.get(this.$route, "query.opendialog", false);
		const open_uuid = ObjectPath.get(this.$route, "query.openuuid", null);

		if (open_dialog && open_uuid) {
			this.viewDialog(open_uuid, "detail");
		}

		this.cost_center_items = this.localDB("cost_center", []);

		const date_range = ObjectPath.get(this.$route, "query.date-range", []);
		if (isString(date_range)) {
			this.s_search["date-range"] = [date_range];
		} else {
			this.s_search["date-range"] = date_range;
		}

		const service_type = ObjectPath.get(this.$route, "query.status-type", []);
		if (isString(service_type)) {
			this.s_search["status-type"] = [service_type];
		} else {
			this.s_search["status-type"] = service_type;
		}

		const service_by = ObjectPath.get(this.$route, "query.service-by", []);
		if (isString(service_by)) {
			this.s_search["service-by"] = [service_by];
		} else {
			this.s_search["service-by"] = service_by;
		}

		const type = ObjectPath.get(this.$route, "query.type", []);
		if (isString(type)) {
			this.s_search["type"] = [type];
		} else {
			this.s_search["type"] = type;
		}

		const acknowledge = ObjectPath.get(this.$route, "query.acknowledge", []);
		if (isString(acknowledge)) {
			this.s_search["acknowledge"] = [acknowledge];
		} else {
			this.s_search["acknowledge"] = acknowledge;
		}

		const cost_center = ObjectPath.get(this.$route, "query.cost-center", []);
		if (isString(cost_center)) {
			this.s_search["cost-center"] = [cost_center];
		} else {
			this.s_search["cost-center"] = cost_center;
		}
	},
};
</script>
